import React, { useEffect } from "react";
import ReactGA from "react-ga4";

import Info from "./info";
import Footer from "./footer";
import Header from "./header";
import Contacts from "./contacts";

const Home = () => {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Home",
    });
  }, []);

  return (
    <div className="homepage">
      <Header />
      <Info />
      <Contacts />
      <Footer />
    </div>
  );
};

export default Home;
